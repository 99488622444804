:root {
    --sn-main-color: #FB6C6D;
    --sn-text-main-color: #333333;
    --sn-grey-color: #F5FCFE;
    --sn-white-color: #ffffff;
    --sn-greymain-color: #CBC5C5;
    --sn-redmain-colort: #FF0000;
}

body {
    font-size: 18px;
    color: var(--po-text-main-color);
    font-family: "Inter", sans-serif;
    line-height: 1.8;

}

a {
    color: var(--po-text-main-color);
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: var(--ph-main-color);
    text-decoration: none;
}

.po-active {
    color: var(--ph-main-color) !important;
}

img {
    max-width: 100%;
    height: auto;
}

:focus {
    outline: none;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}
section{
    padding: 60px 0;
}


.ps-btn {
    color: var(--sn-white-color);
    background: var(--sn-main-color);
    text-transform: capitalize;
    border: none;
    padding: 7px 22px;
    transition: .6s all;
    position: relative;
    /* z-index: 1; */
    overflow: hidden;
    border-radius: 30px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid var(--sn-main-color);
}
/* .sn-ninja-header-btn{
  animation: mymoveheader 2s infinite;
} */
.sn-ninja-header-btn {
    animation: mymoveheader 2s infinite;
    padding: 8px 18px 10px 20px;
    border-radius: 30px;
    color: var(--sn-white-color);
}
.sn-ninja-header-btn:hover{
    color: var(--sn-white-color);
}
@keyframes mymoveheader {
  from {background-color: red;}
  to {background-color: #fb6c6d;}
}

.ps-btn:hover {
    background: var(--ph-text-main-color);
    color: var(--ph-white-color);
}
.ps-main-wrapper-header {
/*    box-shadow: 0 0 6px 3px rgb(33 37 41 / 5%);*/
}   
/* end */


/* header */
.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li {
    margin-right: 35px;
}


.ps-main-nav .offcanvas-header {
    justify-content: end;
}

.ps-main-nav .navbar-toggler {
    border: 0;
    padding: 0;
}

.ps-main-nav .navbar-toggler:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
}

.ps-main-wrapper-header .ps-logo img {
    max-height: 50px;
}

.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li {
    margin-right: 55px;
}

.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--ph-text-main-color);
}

.ps-main-wrapper-header .ps-main-nav .ps-tree-menu {
    border-radius: 0;
    border: 0;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 5%);
    padding: 0;
}

.ps-main-wrapper-header .ps-main-nav .ps-tree-menu a {
    padding: 10px 15px;
    border-radius: 0px;
    font-size: 13px !important;
}

.ps-main-wrapper-header .ps-main-nav .ps-tree-menu.dropdown-menu {
    max-width: 140px;
    min-width: 140px;
    width: 100%;
    margin: 8px auto;
}

.ps-main-wrapper-header .ps-main-nav .ps-tree-menu a:hover {
    background: var(--ph-main-color);
    color: var(--ph-white-color);
}

.offcanvas.offcanvas-end {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    width: 280px;
}

.bt-slidebar .offcanvas.offcanvas-end {
    max-width: 280px;
    width: 280px;
}


/*Custom Css Start*/

/*banner css*/
.sn-tagline-platform small {
    background: var(--sn-white-color);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px 20px;
    border-radius: 22px;
    font-weight:500;
}
.sn-banner-main {
    background: url(../images/banner-bg.webp) no-repeat center center;
    -o-object-fit: cover;
/*    object-fit: cover;*/
    background-size: cover;
/*    position: relative;*/
    padding: 150px 0 70px;
/*    overflow: hidden;*/
    margin-top: -75px;
}
.sn-main-wrapper-header header {
    background: transparent;
}
.sn-tagline-platform small img {
    margin-right: 10px;
    width: 100%;
    max-width: 25px;
}
.sn-tagline-platform {
    margin-top: 100px;
}
.sn-tagline-platform h1 {
    font-size: 38px;
    margin-top: 50px;
    margin-bottom:30px;
}
.sn-tagline-platform p {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}
.bs-login .sn-free-trail-btn {
    color: var(--sn-white-color);
    background:var(--sn-greymain-color);
    text-transform: capitalize;
    border: none;
    padding: 7px 22px;
    transition: .6s all;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 30px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid var(--sn-greymain-color);
}
.sn-banner-main .bs-login a {
    width: 120px;
}
.sn-banner-main .bs-login {
    margin-top: 40px;
    margin-bottom: 40px;
}
.sn-take-product-bnr a {
    color: red;
    font-weight: 500;
}
.sn-heading-title h2 {
    font-size: 28px;
    font-weight: 600;
}
.sn-heading-title h2 span {
    color: var(--sn-main-color);
}

.sn-tab-feature ul {
    border-bottom: 1px solid var(--sn-greymain-color);
    justify-content: space-between;
}
.sn-tab-feature ul li button.active {
    background: transparent !important;
    color: var(--sn-main-color) !important;
    border-bottom: 1px solid;
    border-radius: 0;
}
.sn-tab-feature .sn-icon-tab {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    line-height: 23px;
    margin-right: 5px;
    border: 1px solid var(--sn-main-color);
    color: var(--sn-main-color);
}
.sn-tab-feature .sn-icon-tab svg {
    color: red;
}
.sn-tab-feature ul li button {
    color: #333;
    font-weight: 500;
    font-size: 18px;
}
.sn-tab-feature ul li button.active span {
    background: var(--sn-main-color); 
}
.sn-tab-feature ul li button.active span svg{
    color: var(--sn-white-color); 
}
.sn-tab-content-maim h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
}
.sn-tabbgmain {
    background: var(--sn-grey-color);
    border-radius: 6px;
    padding: 31px 0 19px 40px;
}
.sntab-content h4 {
    font-size: 18px;
    color: var(--sn-main-color);
}
.sntab-content p {
    font-size: 14px;
}
.sn-tab-content-maim {
    margin-bottom: 0px;
}
.sntab-content {
    margin-top: 0px;
}
.snmain-client-sec  .swiper-pagination {
    top: 53px;
}
.sn-swiper-main-comapnt {
    margin-top: 40px;
}
.sn-swiper-main-comapnt .swiper-pagination-bullet-active {
    background: var(--sn-main-color);
}
.sn-swiper-main-comapnt {
    height: 125px;
    margin-bottom:70px;
}
.snmain-client-sec {
    background:var(--sn-grey-color);
}
.sn-ninja-main-features {
    text-align: right;
    margin-bottom: 70px;
}
.sn-data-feature {
    background: var(--sn-white-color);
    border-radius: 6px;
    padding: 45px 20px 45px 20px;
    margin-top:100px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.sn-ninma-feature-mobile {
    text-align: center;
    margin-top: -125px;
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
.sn-ninja-main-features-right{
     text-align: left;
    margin-bottom: 70px;
}
.sn-ninja-main-features h4 {
    font-size: 20px;
    color:var(--sn-main-color);
}
.sn-ninja-main-features-right h4 {
    font-size: 20px;
    color:var(--sn-main-color);
}
.sn-modern-ui-description h3 {
    color: #333;
    font-weight: 600;
    font-size: 28px;
}
.sn-modern-ui-description h3 span {
    color:var(--sn-main-color);
}
.sn-modern-list span {
    font-weight: 600;
    color: var(--sn-main-color);
}
.sn-modern-list p {
    margin-left: 30px;
}
.sn-modern-about{
    margin-top: 100px;
}
.sn-why-choose-grid ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}
.sn-why-choose-grid ul li {
    text-align: center;
    padding: 20px 10px 15px;
    transition: all 0.5s;
    border: 1px solid transparent;
    border-radius: 10px;
}
.sn-why-choose-grid ul li h3 {
    font-size: 18px;
    text-transform: capitalize;
    margin: 0;
    font-weight: 400;
    pointer-events: none;
}
.sn-why-choose-grid ul li h2 {
    display: inline-block;
    font-weight: 700;
    pointer-events: none;
    margin-top: 10px;
    color: var(--sn-main-color);
}
.sn-client-sec {
    background: var(--sn-grey-color);
}
.sn-client-main-box {
    background: var(--sn-white-color);
    padding: 30px 30px 20px 30px;
    border-radius: 34px;
    width: 100%;
    box-shadow: rgb(0 0 0 / 0%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 0%) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    margin-bottom: 13px;
    margin-top: 13px;
    border: 1px solid #9e9e9e38;
}
.sn-client-logo-sec {
    text-align: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;
}
.sn-client-logo-sec .sn-clint-logom {
    width: 100%;
    max-width: 80px;
}
.sn-question-img {
    width: 100%;
    max-width: 30px;
}
.mySwiperClinet {
    height: auto;
}
.sn-clinet-details img {
    width: 100%;
    max-width: 60px;
    border-radius: 100%;
    border: 2px solid var(--sn-main-color);
    margin-right: 20px;
}
.sn-client-name h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.sn-client-name small {
    font-size: 14px;
}
.sn-clinet-details {
    display: flex;
    align-items: center;
    border-top: 2px solid #BCBFDB;
    padding-top: 15px;

}
.mySwiperClinet .swiper-slide {
    height: 360px;
}
.sn-blog-main-sec h3 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 40px;
}
.sn-blog-sale {
    display: flex;
    margin: auto;
    display: inline-flex;
    align-items: baseline;
}
.sn-blog-sale h5 {
    font-size: 16px;
    color: var(--sn-main-color);
    margin: 0;
}
.sn-blog-sale p {
    font-size: 14px;
    color: #979AB4;
    margin-left: 20px;
/*    margin: 0;*/
}
.sn-blog-sale p:after {
    content: "";
    width: 7px;
    height: 7px;
    background: var(--sn-greymain-color);
    display: block;
    border-radius: 100%;
    position: relative;
    bottom: 16px;
    left: -10px;
}
.sn-news-form-buttn button {
    background: var(--sn-main-color);
    border: 0;
    color: var(--sn-white-color);
    font-size: 15px;
    height: 60px;
    padding: 0px 20px 0px 20px;
    border-radius: 6px;
    box-shadow:rgb(17 21 26 / 6%) 1px 2px 0px;
    margin-left: 10px;
    width:120px;
}
.sn-news-form-buttn input {
    border: 1px solid #C9CCE2;
    border-radius: 6px;
    padding-left: 10px;
    height: 60px;
/*    border-left: 0;*/
    width: 80%;
/*    border-left: 0;*/
    box-shadow: rgb(17 21 26 / 6%) 1px 2px 0px;
    padding-left: 40px;
}
.sn-news-form-buttn input::placeholder {
    font-size: 14px;
    color: #C9CCE2;
}
.sn-news-form-buttn {
    margin: auto;
    text-align: center;
}
.sn-news-form-buttn .input-group-text {
   /* height: 48px;
    background: var(--sn-white-color);
    border-radius: 0;
    position: relative;
    margin-right: 0;
    right: -10px;
    top: 1px;
    border-radius: 0;
    box-shadow: rgb(17 21 26 / 6%) 0px 2px 0px;
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;*/
}
.sn-news-form-buttn .input-group-text svg {
    color: #C9CCE2;
}
.sn-news-sectiondiv {
    background: var(--sn-grey-color);
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 10px;
}
.sn-pricng-plan-main {
    /* display: flex; */
    align-items: center;
    text-align: center;
}
.sn-pricng-plan-main h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.sn-pricng-plan-main img {
    width: 100%;
    max-width: 25px;
    /* margin-right: 15px; */
}
.sn-pricing-title-basic h3 {
    font-size: 24px;
    margin-top: 10px;
}
.sn-pricing-title-basic h3 small {
    color: var(--sn-main-color);
    font-size: 14px;
    font-weight: 600;
}
.sn-pricing-title-basic p {
    color:#9392A3;
    font-size: 14px;
    margin: 0;
}
.sn-pricing-title-basic {
    border-bottom: 1px solid #cccccc6b;
    padding-bottom: 6px;
}
.sn-pricng-list ul li span {
    background: var(--sn-main-color);
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
    color: var(--sn-white-color);
    margin-right: 12px;
}
.sn-pricng-list ul li span svg {
    width: 15px;
}
.sn-pricng-list ul li p {
    margin: 0;
    font-size: 16px;
}
.sn-pricng-list ul li {
    display: flex;
    margin-bottom: 20px;
}
.sn-pricng-list {
    margin-top: 35px;
}
.sn-pricing-main-box-div {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
/*    padding: 40px 30px 16px 30px;*/
    border-radius: 10px;
}

.sn-pricng-basic {
/*    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;*/
    padding: 35px 35px 35px 35px;
    border-right: 2px solid #cccccc6b;
    height: 100%;
    position: relative;
    padding-bottom: 100px;
}
.sn-pricing-box-one {
    padding-right: 0;
}
.sn-pricing-second-right {
    padding-left: 0;
}
.sn-form-bg-left h3 {
    font-size: 22px;
}
.sn-form-bg-left p {
    font-size: 16px;
    color: #787575;
}
.sn-contactlist-main ul li img {
    width: 100%;
    max-width: 16px;
    margin-right: 10px;
}
.sn-contactlist-main ul li {
    margin-top: 11px;
}
.sn-contactlist-main {
    border-top: 1px solid #D9D9D9;
    padding-top: 15px;
}
/*.sn-form-bg-contact {
    background: #FFEAEA;
    border-radius: 6px;
    padding: 40px 40px 40px 40px;
}*/
.sn-form-bg-contact {
/*    background: url(../images/bgg.png) no-repeat center center;*/
    border-radius: 6px;
    padding: 40px 40px 140px 40px;
    background: #ffeaea;
    position: relative;
}
.sn-form-div-start input {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.sn-form-div-start label {
    color: #D3D2D7;
    font-size: 14px;
}
.form-floating textarea {
    border: 0;
 border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.sn-form-div-start .sn-sendmst-contact {
    background: var(--sn-main-color);
    border: 0;
    border-radius: 40px;
    padding: 9px 25px 9px 25px;
    margin-top: 15px;
}
.sn-contact-form-main {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 5px;
    padding: 40px 40px 40px 40px;
    margin-top:40px;
/*    position: relative;*/
}
 #scrollToTopBtn {
        /* display: none; */
        position: fixed;
        bottom: 20px;
        right: 20px;
/*        z-index: 99;*/
        background-color:transparent;
/*        color: white;*/
        border: none;
/*        border-radius: 50%;*/
/*        padding: 10px;*/
        cursor: pointer;
    }
.form-control:focus {
    color: #212529;
    background-color: var(--sn-white-color);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: inherit;
}
.sn-footer-logo .sn-social-icons {
    display: flex;
    align-items: center;
}
.sn-footer-logo .sn-social-icons li a {
    background:#fb6c6d00;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height:37px;
    border-radius: 50%;
    color: var(--sn-main-color);
    border: 1px solid #fd0204;
    border-style: dotted;
    display: inline-block;
}
.sn-footer-logo .sn-social-icons li a:hover {
    background:var(--sn-main-color);
    color: var(--sn-white-color);
    transition: 0.5s;
}
.sn-footer-logo p {
    margin-top: 30px;
    padding-bottom: 20px;
}
.sn-footer-main {
    background: #EDEDED;
}
.sn-footer-main .sn-footer-menu h3 {
    font-size: 18px;
    font-weight: 600;
    color:  var(--sn-main-color);
}
.sn-footer-menu ul li a {
    font-size: 16px;
}
.ph-footer-content .ph-content-f {
    display: flex;
    align-items: center;
}
.ph-footer-content .ph-content-f {
    color: var(--ph-white-color);
}
.ph-footer-content p {
    display: flex;
    gap: 10px;
    grid-gap: 10px;
    font-size: 16px;
    color:#333;
    align-items: center;
}
.ph-footer-content .ph-content-f p:first-child {
    margin-right: 20px;
}
.sn-footer-copyright {
    background: var(--sn-main-color);
    text-align: center;
    color: var(--sn-white-color);
}
.sn-footer-copyright p {
    margin: 0;
    line-height: 50px;
}
.sn-footer-menu ul li a:hover {
    color: var(--sn-main-color);
    margin-left: 3px;
    transition: 0.5s;
    font-weight:500;
}
.sn-banner-main .bs-login a {
    width: 120px;
    display: inline-block;
    text-align: center;
    padding: 5px 5px;
}
.sn-feature-start .sntab-content:hover p {
/*    color:var(--sn-main-color);*/
    transition: 0.5s;
}
.sn-ninja-main-features:hover img {
    -moz-transform: rotateZ(720deg);
    transition: 0.9s;
    transform: rotateY(360deg);
}
.sn-ninja-main-features:hover p {
    color:var(--sn-main-color);
    transition: 0.5s;
}
.sn-ninja-main-features-right:hover img {
    -moz-transform: rotateZ(720deg);
    transition: 0.9s;
    transform: rotateY(360deg);
}
.sn-ninja-main-features:hover p{
    color:var(--sn-main-color);
    transition: 0.5s;
}
.sn-ninja-main-features-right:hover p{
    color:var(--sn-main-color);
    transition: 0.5s;
}

.sn-blog-main-sec figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .5s ease-in-out;
}
.sn-blog-main-sec:hover figure img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.sn-blog-main-sec figure{
    overflow: hidden;
    border-radius: 20px;
}
.sn-news-email {
    position: absolute;
    left: 10px;
    color: #b2bbdb;
    top: 14px;
}
.sn-top-meny-navbar li a {
    font-weight: 600 !important;
    color: #333 !important;
}

.sn-contact-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fed1d147;
    position: absolute;
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
    right: 20px;
    bottom: 20px;
}
.sn-shap-upDown {
    position: absolute;
    right: 0;
    opacity: 25%;
    top: 20%;
}
.sn-shap-elements {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
/*Image animation*/
@keyframes up-down
{0%{transform:translateY(10px)}100%{transform:translateY(-10px)}
}
.ph-footer-content .ph-content-f p svg {
    /* width: 20px;
    height: 20px; */
    color: var(--sn-main-color);
}

/* HEADER STICKY
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.15);
}
.page-header.is-sticky {
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
/*  padding: 8px 0;*/
/*  backdrop-filter: blur(10px);*/
background: var(--sn-white-color);
  animation: slideDown 0.35s ease-out;z-index: 9;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.sn-contactlist-main:after {
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    display: block;
    background: #f5fcfe;
    right: 0;
    border-top-left-radius: 100%;
    bottom: 0;
}
/*.sn-animation-circle-bnr {
    position: absolute;
    right: 50%;
    top: 300px;
}

.sn-animation-circle-bnr2 {
    position: absolute;
    right: 40%;
    top: 120px;
}
*/
.sn-animation-circle-bnr3 {
    position: absolute;
    right:10%;
    top: 50%;
}
.sn-modern-list img {
    margin-right: 6px;
    width: 100%;
    max-width: 15px;
}
.sn-blog-main .sn-blog-main-sec:hover a h3 {
    color: var(--sn-main-color);
    transition: 0.5s;
}

/*Blog details pages*/
.sn-blog-detail-banner {
    background: #f3f5f9;
}
.sn-blog-list ul {
    padding-left: 20px;
}
.sn-blog-list ul li {
    list-style: auto;
}
.sn-blog-content-sec .sn-main-blog-sec h5 {
    margin-top: 20px;
}
.sn-blog-detail-banner h1 {
    /*margin-top: 50px;
    margin-bottom: 50px;*/
}
.sn-pricng-list ul li img {
    max-width: 100%;
    width: 25px;
    margin-right: 10px;
    height:25px;
}
.sn-pricing-btn-rgister {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
}
.sn-pricng-list ul li p {
    font-size: 14px;
}
.sn-footer-copy-txt small {
    font-size: 14px;
}
.sn-blog-list ul li p {
    font-size: 14px;
}
.sn-blog-content-sec .sn-main-blog-sec {
    font-size: 14px;
}
.sn-news-sectiondiv .sn-news-form-buttn label {
    position: relative;
}
.sn-contact-textarea-main textarea {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.sn-new-section .sn-news-section-main {
    display: flex;
}
.sn-new-section .sn-news-section-main .invalid-feedback {
    position: absolute;
}

/*  */

.sn-ninja-header-btn:hover{
    color:var(--sn-white-color);
}
.sn-top-meny-navbar li a:hover {
    color: rgb(248, 9, 9) !important;
    transition: 0.5s;
}

/* expired page */

.sn-expired-section {
    text-align: center;
    width: 100%;
    max-width: 600px;
    padding: 10px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    margin-top: 40px !important;
    margin: auto;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-radius: 4px;
}
.sn-date-sub-btn-main .sn-continue-experied {
    width: 165px;
    border: 2px solid var(--ph-main-color);
    display: inline-block;
    border-radius: 4px;
    color: var(--ph-main-color);
    font-weight: 500;
}
.sn-date-sub-btn-main .sn-subcribe-experied {
    border: 2px solid var(--ph-main-color);
    background: var(--ph-main-color);
    color: var(--sn-white-color);
    width: 160px;
    display: inline-block;
    border-radius: 4px;
}
.sn-date-sub-btn-main {
    width: 100%;
    max-width: 370px;
    display: flex;
    justify-content: space-between;
    margin: auto;
}
.sn-expired-section img {
    width: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.sn-expierd-perragraph p {
    color: #686262;
    margin: 0;
    margin-bottom: 40px;
}
.sn-ninja-header-btn a {
    padding: 20px 20px 20px 20px;
    display: ruby;
}

/* Form Validation */


#my-tooltip-1 {
    background-color: #ecb5b5;
    color: black;
  }
  #my-tooltip-2 {
    background-color: #ecb5b5;
    color: black;
  }
  #my-tooltip-3 {
    background-color: #ecb5b5;
    color: black;
  }
  #my-tooltip-4 {
    background-color: #ecb5b5;
    color: black;
  }
  .sn-refreshicon-sns {
    width: 15px;
    object-fit: contain;
    margin-left: 10px;
    margin-top: -5px;
    margin-right:10px;
}
.sn-terms-condition-txt-main h6 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}
.sn-privacy-policy-list ul {
    padding-left: 20px;
}
.sn-privacy-policy-list ul li {
    list-style: inside;
}

/* modal video css */

.sn-header-video-section a img {
    width: 100%;
    max-width: 30px;
    height: 30px;
    min-width: 30px;
}
.sn-webvideproduct .modal-dialog {
    width: 100%;
    max-width: 800px;
    max-width: 800px;
}
img.sn-flat-img {
    width: 100%;
    min-width: 23px;
    max-width: 23px;
    object-fit: contain;
}
.sn-website-form-email .ph-content-f {
    display: flex;
}
.sn-website-form-email .ph-content-f svg {
    margin-right: 10px;
    color: var(--sn-main-color);
}
.sn-web-country-contact {
    margin-left: 48px;
}
.sn-web-country-contact .ph-contact-footer {
    margin-right: 10px !important;
}
/* modal video css */
.close-btn-video-bnr {
    opacity: 100;
    background: transparent;
    
}
.close-btn-video-bnr img {
    width: 30px;
    height: 30px;
    max-width: 30px;
    top: 0;
    padding-top: 0;
    margin-top: -20px;
}
button.btn-close.close-btn-video-bnr:focus {
    border: 0;
    box-shadow: 0 0 0 .25rem rgb(13 110 253 / 0%);
}







