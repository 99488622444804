
@media only screen and (max-width: 767px) {


.sn-tagline-platform {
    margin-top:0px !important;
}

.sn-ninma-feature-mobile {
    margin-top: 0 !important;
}
.sn-why-choose-grid ul {
    grid-template-columns: repeat(2, 1fr);

}
.sn-news-form-buttn input {
    width: 70%;
}
.sn-news-form-buttn button {
    /* margin-top: 20px; */
}
.sn-contact-form-main {
    padding: 10px 10px 10px 10px !important;
}
.sn-modern-image-sec {
    margin-bottom: 30px;
}
.sn-form-bg-contact {
    padding: 20px 20px 20px 20px;
}
.sn-footer-logo .sn-social-icons {
    margin-bottom: 30px;
}
.sn-footer-main .sn-footer-menu h3 {
    margin-top: 20px;
}
.sn-pricng-basic {
    box-shadow: inherit;
}
.sn-login-register-btn-top {
    margin-top: 12px;
}
.sn-pricng-basic {
    padding: 20px 20px 20px 20px;
    border-right: 0;
}
.sn-pricng-list ul li span {
/*    width: 40px;*/
}
/*menu bar*/
.ps-main-nav .offcanvas-header {
    justify-content: end;
    padding-bottom: 0;
}
.ps-main-nav .bt-slidebar {
    padding-top: 0;
}
/**/
.offcanvas.offcanvas-end {
/*    left: 0;*/
}
.sn-client-sec .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.sn-ninja-main-features {
    text-align: left;
}
.sn-contactlist-main:after {
    display: none;
}
.sn-footer-main {
    padding-bottom: 0;
}
.sn-footer-copyright p {
    line-height: inherit;
    font-size: 14px;
}
.sn-tagline-platform small {
    font-size: 12px;
}
.ps-main-wrapper-header .ps-logo img {
    width: 100%;
    max-width: 160px;
}
.sn-tagline-platform h1 {
    font-size: 28px;
}
.sn-pricing-btn-rgister {
    bottom: -30px;
}
.sn-news-form-buttn form {
    text-align: center;
    margin: auto;
}
.sn-banner-main {
    padding: 50px 0 70px;
}
.sn-ninja-header-btn {
    text-align: center;
}


}


/*Ipad media query*/

/* iPad portrait mode */
@media only screen and (min-width: 768px) and (max-width: 1024px){
.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li a {
    font-size: 12px;
}
.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li {
    margin-right: 25px;
}
.ps-logo img {
    width: 100%;
    max-width: 140px;
}
.sn-tagline-platform small {
    font-size: 12px;
}
.sn-tagline-platform h1 {
    font-size: 22px;
}
.sn-news-form-buttn form {
    text-align: center;
    margin: auto;
}
.sn-banner-main {
    padding: 50px 0 70px;
}
.sn-ninja-header-btn {
    text-align: center;
}

}

/*Ipad Pro media query*/

@media only screen and (min-width: 1024px) and (max-width: 1366px){
.ps-main-wrapper-header .ps-main-nav .ps-menu>ul>li a {
    font-size: 12px;
}
.ps-main-wrapper-header .ps-logo img {
    width: 100%;
    max-width: 150px;
}
.sn-news-form-buttn form {
    text-align: center;
    margin: auto;
}
.sn-banner-main {
    padding: 50px 0 70px;
}
.sn-ninja-header-btn {
    text-align: center;
}


}







